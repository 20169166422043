import { CloseOutlined } from '@mui/icons-material';
import { Box, IconButton, Modal, Typography } from '@mui/material'
import { useModal } from 'Modals'
import React from 'react'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 500,
    bgcolor: 'secondary.light',
    border: '1px solid #000',
    borderRadius: 2,
    boxShadow: 24,
}

const buttonStyle = {
    position: 'absolute',
    top: '-8%',
    right: '-8%',
    bgcolor: '#9e9e9e50',
    color: 'white'
}

const ImageModal = () => {
    const { imageModal: { show, title, link }, hideImage } = useModal()

    return (
        <Modal
            open={show}
            onClose={hideImage}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div>
                <Box sx={style}>
                    <IconButton color='inherit' sx={buttonStyle} onClick={hideImage}>
                        <CloseOutlined />
                    </IconButton>
                    {title ? (
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {title}
                        </Typography>
                    ) : null}
                    <div style={{ overflow: 'hidden', borderRadius: 8, height: '100%', width: '100%' }}>
                        {link ? (
                            <img
                                srcSet={`${link}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                src={`${link}?w=164&h=164&fit=crop&auto=format`}
                                alt={link}
                                height="auto"
                                width={500}
                                loading="lazy"
                            />
                        ) : null}
                    </div>
                </Box>
            </div>
        </Modal>
    )
}

export { ImageModal }