import * as React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client'
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { App } from 'App';
import theme from 'theme';
import { BrowserRouter } from "react-router-dom"
import { AuthProvider } from 'Auth';
import { ModalProvider } from 'Modals';
import { DrawerProvider } from 'Drawer';
import { AppProvider } from 'App/AppContext';
import { SnackbarProvider } from 'Components/Snackbar';
import packageJson from '../package.json';
global.appVersion = packageJson.version;

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter basename="/admin">
    <AppProvider>
      <AuthProvider>
        <DrawerProvider>
          <ModalProvider>
            <SnackbarProvider>
              <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <App />
              </ThemeProvider>
            </SnackbarProvider>
          </ModalProvider>
        </DrawerProvider>
      </AuthProvider>
    </AppProvider>
  </BrowserRouter>
);

// ReactDOM.render(
//   <BrowserRouter basename="/admin">
//     <AppProvider>
//       <AuthProvider>
//         <DrawerProvider>
//           <ModalProvider>
//             <SnackbarProvider>
//               <ThemeProvider theme={theme}>
//                 {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
//                 <CssBaseline />
//                 <App />
//               </ThemeProvider>
//             </SnackbarProvider>
//           </ModalProvider>
//         </DrawerProvider>
//       </AuthProvider>
//     </AppProvider>
//   </BrowserRouter>,
//   document.querySelector('#root'),
// );
