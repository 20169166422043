import { useCallback, useEffect, useState } from "react";

const reloadPage = () => window.location.reload(true);

export const UpdateStatus = {
    checking: 'checking',
    current: 'current',
    available: 'available',
}

export const useAppUpdateCheck = ({ interval, ignoreServerCache }) => {
    const [status, setStatus] = useState()
    const [version, setVersion] = useState(window.appVersion)

    const checkUpdate = useCallback(() => {
        if (typeof window.appVersion === 'undefined') {
            setStatus(UpdateStatus.current);
            return;
        }

        setStatus(UpdateStatus.checking);

        let requestStr = `${process.env.PUBLIC_URL}/version.txt`;

        if (ignoreServerCache) {
            requestStr += `?v=${Date.now()}`;
        }

        fetch(requestStr)
            .then((res) => res.text())
            .then((version) => {
                if (version.replace(/(\r\n|\n|\r)/gm, "") === window.appVersion) {
                    setStatus(UpdateStatus.current);
                } else {
                    setStatus(UpdateStatus.available);
                    setVersion(version.replace(/(\r\n|\n|\r)/gm, ""))
                }
            })
            .catch(() => {
                setStatus(UpdateStatus.current);
            });
    }, [ignoreServerCache]);

    useEffect(() => {
        checkUpdate();
    }, [checkUpdate]);

    useEffect(() => {
        if (status !== UpdateStatus.current) {
            return;
        }
        const timeoutId = window.setTimeout(
            () => checkUpdate(),
            interval || 10000,
        );

        return () => {
            window.clearTimeout(timeoutId);
        };
    }, [interval, status, checkUpdate]);

    return { version, status, reloadPage, checkUpdate };
};