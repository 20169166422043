import { Refresh, Close } from '@mui/icons-material';
import { IconButton, Snackbar } from '@mui/material';
import { UpdateStatus, useAppUpdateCheck } from 'hooks/UseAppUpdateCheck';
import React, { useState } from 'react';

const UpdateNotificationContainer = () => {
    const { version, status, reloadPage } = useAppUpdateCheck({
        ignoreServerCache: true,
        interval: 60000,
    });

    const [open, setOpen] = useState(true)

    if (status === UpdateStatus.checking || status === UpdateStatus.current) {
        return null;
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="refresh"
                color="inherit"
                onClick={reloadPage}
            >
                <Refresh fontSize="small" />
            </IconButton>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <Close fontSize="small" />
            </IconButton>
        </React.Fragment>
    )

    return (
        <Snackbar
            open={open}
            onClose={handleClose}
            message={<div>New App update available <i><b>{version}</b></i></div>}
            action={action}
        />
    )
};

export { UpdateNotificationContainer }