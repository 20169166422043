import { useRef, useState } from "react"
import { useModal } from "./index"
const { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Autocomplete, TextField } = require("@mui/material")

const Modal = () => {
    const { modal: { title, content, showReject, rejectReasons, message, show, leftButton, leftButtonAction, rightButton, rightButtonAction }, hideModal } = useModal()
    const [selectedReason, setSelectedReason] = useState(null)
    const reasonDescription = useRef()

    const getReason = () => {
        if (selectedReason === "OTHER") {
            return reasonDescription.current.value
        }
        return rejectReasons.find(reason => reason.code === selectedReason).name
    }

    return (
        <Dialog
            open={show}
            maxWidth="sm"
            fullWidth
            onClose={hideModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
                {content}
                {showReject ? (
                    <div style={{ marginTop: 16 }}>
                        {rejectReasons ? (
                            <Autocomplete
                                name="reason"
                                label="Rejection Reason"
                                variant="outlined"
                                required
                                size="small"
                                disableClearable
                                value={selectedReason ? rejectReasons.find(reason => reason.code === selectedReason) : null}
                                options={rejectReasons}
                                isOptionEqualToValue={(option, value) => {
                                    return option.code === value.code
                                }}
                                getOptionLabel={(item) => item.name ? item.name : ""}
                                onChange={(event, data) => setSelectedReason(data.code)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        margin="dense"
                                        style={{ marginTop: 0, marginBottom: 0 }}
                                        InputProps={{
                                            ...params.InputProps,
                                        }}
                                    />
                                )}
                            />
                        ) : null}
                        {selectedReason === "OTHER" ? (
                            <TextField
                                margin='dense'
                                size='small'
                                label="If Other, describe reason"
                                fullWidth
                                inputRef={reasonDescription}
                            />
                        ) : null}
                    </div>
                ) : null}
            </DialogContent>
            <DialogActions>
                {leftButton ? <Button onClick={leftButtonAction}>{leftButton}</Button> : null}
                <Button onClick={() => showReject ? rightButtonAction(getReason()) : rightButtonAction()} autoFocus>
                    {rightButton}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export { Modal }